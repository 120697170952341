.table th {
  font-weight: 400;
  color: #757575;
}

.table th,
.table td {
  padding: 16px;
}

.table td {
  font-weight: 500;
}

.table > tbody > tr:nth-child(odd) {
  background-color: #f5f5f5;
}

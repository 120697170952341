.inputBorders > :not(:last-child) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.inputBorders > :not(:last-child):not(:first-child) {
  border-radius: 0px;
  border-left-width: 0;
  border-right-width: 0;
}

.inputBorders > :not(:first-child):last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.inputBorders > .inputWrapper:not(:last-child) {
  border-right-width: 0;
}

.inputBorders > .inputWrapper:not(:first-child) {
  border-left-width: 0;
}

.modalWrapper {
  visibility: hidden;

  transform: translate(0, 100%);
  opacity: 0;
  transition-property: transform, visibility, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.modalWrapper.isOpen {
  visibility: visible;

  opacity: 100%;
  transform: translate(0, 0);
}

.coverWrapper {
  background-color: rgb(0, 0, 0, 0);
  z-index: 9;
  transition: background-color 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.coverWrapper.isOpen {
  background-color: rgb(0, 0, 0, 0.5);
}

@media (min-width: 1024px) {
  .modalWrapper {
    transform: translate(0, -30px);
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}

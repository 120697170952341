.btn {
  transition:
    box-shadow 500ms cubic-bezier(0.19, 1, 0.22, 1),
    color 200ms,
    background-color 200ms,
    border-color 200ms;
}

.btn:active {
  transition: none;
}

.btnNeutral:active {
  box-shadow: 0px 0px 0px 4px #e0e0e0;
}

.btnPrimary:active {
  box-shadow: 0px 0px 0px 4px rgba(151, 71, 255, 0.2);
}

.btnDanger:active {
  box-shadow: 0px 0px 0px 4px rgba(203, 58, 49, 0.2);
}

.btnWarning:active {
  box-shadow: 0px 0px 0px 4px rgba(205, 123, 46, 0.2);
}

.btnSuccess:active {
  box-shadow: 0px 0px 0px 4px rgba(67, 147, 108, 0.2);
}

.btnInfo:active {
  box-shadow: 0px 0px 0px 4px rgba(62, 143, 174, 0.2);
}

.navWrapper {
  transform: translate(-100%, 0);
  transition: transform 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.navWrapper.isOpen {
  transform: translate(0, 0);
}

.coverWrapper {
  background-color: rgb(0, 0, 0, 0);
  z-index: 9;
  transition: background-color 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.coverWrapper.isOpen {
  background-color: rgb(0, 0, 0, 0.5);
}

@media (min-width: 1024px) {
  .navWrapper {
    transition: none;
    transform: translate(0, 0);
  }
}
